export default {
    NEWS_TYPES: [
        {
            text: 'GENERIC',
            value: 0
        },
        {
            text: 'UPDATE',
            value: 1
        },
        {
            text: 'SEASON_PASS',
            value: 2
        },
        {
            text: 'OPERATING_SYSTEM',
            value: 3
        }
    ],
    ACTION_BUTTON_TYPES: [
        {
            text: 'GENERIC',
            value: 0
        },
        {
            text: 'DISCORD',
            value: 1
        },
        {
            text: 'FACEBOOK',
            value: 2
        },
        {
            text: 'INSTAGRAM',
            value: 3
        },
        {
            text: 'RUMBLE_PASS',
            value: 4
        },

    ],
    OPERATING_SYSTEMS: [
        {
            text: 'IOS',
            value: "ios"
        },
        {
            text: 'ANDROID',
            value: "android"
        }
    ]
}